<template>
  <div class="content">
    <div class="content__party content__party--start">
      <h1 class="title title--big title--color">Экспорт</h1>
    </div>
    <div class="export">
      <div class="export__block">
        <div class="export__title">Экспорт в Excel</div>
        <div class="export__list">
          <button
            type="button"
            @click="onGetEYCA('Экспорт выпущенных карт МКП РСМ & EYCA')"
            class="export__item"
            :class="{ 'export__item--loading': loader_eyca }"
          >
            <img src="@/assets/img/xls-icon.svg" alt="" class="export__icon">
            <div class="export__name">Экспорт выпущенных карт МКП РСМ & EYCA</div>
            <img class="export__loader" src="@/assets/img/preloader-theme.svg" alt="">
          </button>
          <button
            type="button"
            @click="onGetGPB('Экспорт карт ГПБ')"
            class="export__item"
            :class="{ 'export__item--loading': loader_gpb }"
          >
            <img src="@/assets/img/xls-icon.svg" alt="" class="export__icon">
            <div class="export__name">Экспорт карт ГПБ (Общий список)</div>
            <img class="export__loader" src="@/assets/img/preloader-theme.svg" alt="">
          </button>
          <button
            type="button"
            @click="onGetStat('Экспорт статистики по университетам')"
            class="export__item"
            :class="{ 'export__item--loading': loader_stat }"
          >
            <img src="@/assets/img/xls-icon.svg" alt="" class="export__icon">
            <div class="export__name">Экспорт статистики по университетам (ГПБ)</div>
            <img class="export__loader" src="@/assets/img/preloader-theme.svg" alt="">
          </button>
          <button
            type="button"
            @click="onGetPart('Экспорт партнеров')"
            class="export__item"
            :class="{ 'export__item--loading': loader_part }"
          >
            <img src="@/assets/img/xls-icon.svg" alt="" class="export__icon">
            <div class="export__name">Экспорт партнеров</div>
            <img class="export__loader" src="@/assets/img/preloader-theme.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Export',
  data() {
    return {
      loader_gpb: false,
      loader_stat: false,
      loader_part: false,
      loader_eyca: false,
    };
  },
  computed: {
    getCurrentDate() {
      const currentdate = new Date();
      return `${currentdate.getDate()}_${
        currentdate.getMonth() + 1}_${
        currentdate.getFullYear()} ${
        currentdate.getHours()}_${
        currentdate.getMinutes()}`;
    }
  },
  methods: {
    onGetGPB(name) {
      this.loader_gpb = true;
      this.$store.dispatch('admin/exportExcel/GET_EXPORT_EYCA')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name} ${this.getCurrentDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.loader_gpb = false;
        });
    },
    onGetStat(name) {
      this.loader_stat = true;
      this.$store.dispatch('admin/exportExcel/GET_EXPORT_STAT')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name} ${this.getCurrentDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.loader_stat = false;
        });
    },
    onGetPart(name) {
      this.loader_part = true;
      this.$store.dispatch('admin/exportExcel/GET_EXPORT_PARTNER')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name} ${this.getCurrentDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.loader_part = false;
        });
    },
    onGetEYCA(name) {
      this.loader_eyca = true;
      this.$store.dispatch('admin/exportExcel/GET_EXPORT_GPB')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name} ${this.getCurrentDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.loader_eyca = false;
        });
    },
  }
};
</script>
